<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="items"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Vídeos por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #nombre="{ item }">
          <td>
            <a
              class="text-info"
              @click="informacionVideo(item.acciones)"
            >{{
              item.nombre
            }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="editVideo(item.acciones)"
            >
              Editar
            </CButton>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="botonEliminar(item)"
            >
              Eliminar
            </CButton>

            <CModal
              id="modalElim"
              title="Eliminar video"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el vídeo titulado
                {{ video.nombre }}? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteVideo(video.acciones)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "Table",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [{key: "nombre"},
          {
            key: "descripcion",
            label: "Descripción"
          },
          {
            key: "categoria",
            label: "Categoría"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      video: {},
    };
  },
  methods: {
    /**
     * Evento que se ejecuta al pulsar el boton editar. 
     * Nos llevara al formulario de ediccion del video.
     * 
     * @param {number} id - El id del video a modificar
     */
    editVideo(id) {
      this.$router.push("/video/editVideo/" + id);
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} video - El modelo del video sobre el que se ha llamado al evento de eliminar
     */
    botonEliminar(video) {
      this.video = video;
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el video, mandando por la URL el id
     * 
     * @param {number} id - El ID del video a eliminar
     */
    deleteVideo(id) {
      const token = localStorage.token;
      axios.delete(process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS + id, {
          headers: {
            Authorization: token,
          },
        }).then(() => {
          //con esto actualizamos la página para que se actualice la tabla
          location.reload();
        }).catch(function (error) {
          if (error.response) {
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
      this.deleteModal = false;
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del video, nos llevará a la página para ver la información del video
     * 
     * @param {number} id - El ID del video a mostrar su informacion
     */
    informacionVideo(id) {
      this.$router.push("/video/infoVideo/" + id);
    },
  },
};
</script>
