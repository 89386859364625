<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <div class="d-flex justify-content-between">
          <select
            id="categorias"
            class="form-select"
            @change="categoryFilterChanged()"
          >
            <option
              v-for="accion in getCategorias()"
              :key="accion.category_id"
            >
              {{ accion.category_name }}
            </option>
          </select>
        </div>
        <CTableWrapper
          :items="datos_show"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Vídeos
              <CButton
                class="btn btn-success"
                size="sm"
                @click="addVideo()"
              >
                Añadir video
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaVideo";
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";

/**
 * Función para conseguir los datos de los videos 
 * 
 * @returns {Promise<object>} Promesa que al resolverse da los datos de los videos
 */
async function getVideoData() {
  let data;
  const token = localStorage.token;
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS, {
      headers: {
        Authorization: token,
      },
    }).then((result) => {
      data = result.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "VideoTable",
  components: { CTableWrapper },
  data() {
    return {
      datos: [],
      categorias: [],
      temp: 0,
      tempV: 0,
      selectedCategoria: "Todas",
      datos_show: [],
    };
  },
  created() {
    //Aquí llamamos al metodo para que se llamen a las funciones y recoger los datos
    this.getCategorias();
  },
  methods: {
    /**
     * Carga los datos de a mostrar en la tabla
     * y los devuelve.
     * 
     * @returns {object[]} Arreglo con los datos a mostrar.
     */
    getVideosData() {
      this.datos_show = this.datos;
      return this.datos_show;
    },
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     * y almacenarlos en la variable datos.
     */
    recibirDatos() {
      if (this.tempV > 0) {
        return this.datos;
      }
      this.getCategorias();
      let data = getVideoData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }

        this.datos = [];
        let element;

        for (let index = 0; index < result.length; index++) {
          element = {};
          element.nombre = result[index].video_name;
          element.descripcion = result[index].video_description;
          let cat;
          for (let ind = 0; ind < this.categorias.length; ind++) {
            if (this.categorias[ind].category_id == result[index].video_category) {
              cat = this.categorias[ind].category_name;
              break;
            }
          }
          element.categoria = cat;
          element.acciones = result[index].video_id;
          this.datos.push(element);
        }
        this.getVideosData();
      });
      this.tempV++;
    },
    /**
     * Función para devolver las categorías.
     * 
     * @returns {object} Las categorías.
     */
    getCategories() {
      return this.categorias;
    },
    /**
     * Metodo para cargas las categorías en la
     * variable categorias que es la que se usara
     * para hacer el filtro.
     * 
     * @returns {object} Las categorias obtenidas.
     */
    getCategorias() {
      if (this.temp > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = [
          {
            id: "Todas",
            category_name: "Todas",
          },
        ];
        this.categorias.push(...result)
        this.temp++;
        this.recibirDatos();
      });
      return this.categorias;
    },
    /**
     * Función que se llama cuando se selecciona otra categoría
     * en el filtro.
     */
    categoryFilterChanged() {
      var selectedCategory = $("#categorias").children("option:selected").val();
      this.selectCategorias(selectedCategory);
    },
    /**
     * Función que cambia los datos que se muestran según la categoría llamada.
     * 
     * @param {string} categoria - El nombre de la categoría seleccionada en el filtro.
     */
    selectCategorias(categoria) {
      if (categoria == "Todas") {
        this.datos_show = this.datos;

        return;
      }

      this.datos_show = [];
      for (let i = 0; i < this.datos.length; i++) {
        const element = this.datos[i];
        if (element.categoria == categoria) {
          this.datos_show.push(element);
        }
      }
    },
    /**
     * Función que carga el formulario para crear un vídeo
     */
    addVideo() {
      this.$router.push("/video/crearVideo");
    },
  },
};
</script>